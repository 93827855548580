/* eslint-disable import/prefer-default-export */
import firebase from 'firebase/app';
import 'firebase/analytics';

const config = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY || 'api_key',
  authDomain: `${process.env.GATSBY_FIREBASE_PROJECT_ID || 'api_key'}.firebaseapp.com`,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID || 'api_key',
  storageBucket: `${process.env.GATSBY_FIREBASE_PROJECT_ID || 'api_key'}.appspot.com`,
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID || 'api_key',
  appId: process.env.GATSBY_FIREBASE_APP_ID || 'api_key',
  measurementId: process.env.GATSBY_FIREBASE_MEASUREMENT_ID || 'api_key',
};

if (!firebase.apps.length) firebase.initializeApp(config);

export const firebaseAnalytics = firebase.analytics();
